<template>
  <div class="add-store-container" v-loading="boxLoading">
    <div class="top-info">
      <span>供应商ID ： {{ data.storeId }}</span>
      <span>供应商名称 ：{{ data.storeName }}</span>
      <span>供应商类型 ： {{ data.storeTypeName }}</span>
      <span>供应商性质 ： {{ data.storeAttrName }}</span>
      <span v-if="data.storeAttr == 1">企业类型：{{ data.companyTypeName }}</span>
      <span>入网状态 ： {{ data.enterNetworkStatus == 'NOT_APPLICATION' ? '待提交申请' : data.enterNetworkStatusName }} ​</span>
    </div>
    <el-form class="form-container" ref="form" :model="form" :rules="rules" label-width="150px">
      <el-row :gutter="24">
        <el-col :span="12" style="border-right: 1px solid #eee">
          <div class="title">
            <span class="title-mark"></span>
            <span class="title-text">提现账户信息</span>
          </div>
          <el-form-item label="银行账户类型:" prop="cardType">
            <el-select disabled v-model="form.cardType" placeholder="请选择银行账户类型">
              <el-option label="对公账户" :value="4"></el-option>
              <el-option label="单位结算卡" :value="3"></el-option>
              <el-option label="借记卡" :value="1"></el-option>
              <el-option label="存折" :value="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="银行账户号码:" prop="cardNumber">
            <el-input disabled v-model="form.cardNumber" placeholder="请输入提现银行账户号码"></el-input>
          </el-form-item>
          <el-form-item label="银行账户开户总行:" prop="bankId">
            <el-select disabled v-model="form.bankId" placeholder="请选择银行账户开户总行">
              <el-option v-for="(item, i) in backList" :key="i" :label="item.dictionaryName" :value="item.dictionaryValue"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12"></el-col>
      </el-row>
      <el-form-item class="form-submit-container">
        <!-- <el-button v-if="data.storeAttr == 1" class="form-submit-btn" type="primary" :loading="submitLoadingStatus" @click="submitForm()">提交</el-button> -->
        <el-button class="form-submit-btn" @click="handleComeBack()">返回</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getDictionaryList } from '@/api/common';
import { storeBank, getStoreBankInfo, getStoreApplyInfo } from '@/api/store';

export default {
  components: {},
  data() {
    return {
      boxLoading: false,
      submitLoadingStatus: false,
      form: {},
      rules: {
        cardType: [{ required: true, message: '请选择银行账户类型', trigger: 'blur' }],
        cardNumber: [{ required: true, message: '请输入提现银行账户号码', trigger: 'change' }],
        bankId: [{ required: true, message: '请选择银行账户开户总行', trigger: 'blur' }],
      },
      action: process.env.VUE_APP_BASE_API,
      data: {},
      backList: [],
    };
  },
  created() {
    this.data = this.$route.query;
    this.getData();
    this.getStoreBankInfo();
    if (this.data.enterNetworkStatus == 'COMPLETED') {
      this.getStoreApplyInfo();
    }
    this.getStoreApplyInfo();
  },
  methods: {
    getCompanyTypeName(key) {
      switch (key) {
        case 1:
          return '个人工商户';
        case 2:
          return '企业';
        case 3:
          return '事业单位';
        case 4:
          return '社会团体';
        default:
          break;
      }
    },
    getStoreApplyInfo() {
      getStoreApplyInfo({ data: this.$route.query.storeId }).then((res) => {
        if (res.data.id) {
          this.data.companyTypeName = this.getCompanyTypeName(res.data.companyType);
          this.$forceUpdate();
        }
      });
    },
    getStoreBankInfo() {
      getStoreBankInfo({ data: this.$route.query.storeId }).then((res) => {
        if (res.data.storeId) {
          this.form = { ...res.data };
          this.form.bankId = this.form.bankId + '';
        }
      });
    },
    async getData() {
      try {
        this.boxLoading = true;
        this.backList = (await getDictionaryList({ data: { key: 'store_tx_bank' } })).data.childs || [];
        this.boxLoading = false;
      } catch (error) {
        this.boxLoading = false;
      }
    },
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoadingStatus = true;
          let data = { ...this.form };
          data.storeId = this.data.storeId;
          storeBank({ data: data })
            .then((res) => {
              this.$notify.success({ title: '成功', message: '操作成功', duration: 2000 });
              this.$router.back();
              this.submitLoadingStatus = false;
            })
            .catch((err) => {
              this.submitLoadingStatus = false;
            });
        }
      });
    },
    handleComeBack() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  background-color: #eee;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.text {
  color: #8c939d;
  width: 158px;
  height: 158px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.avatar {
  width: 158px;
  height: 158px;
  display: block;
}
</style>
